import React, { useEffect } from "react";
import { Route, Switch, useLocation } from "react-router";
import Loading from "../../components/Loading";

// const SignIn = React.lazy(()=>import('../SignIn'))
const Dashboard = React.lazy(() => import("../Dashboard"));

export default function MainLayout() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <React.Suspense fallback={<Loading />}>
      <Switch>
        {/* <Route exact path="/sign-in" component={SignIn} /> */}
        <Route path="/" component={Dashboard} />
      </Switch>
    </React.Suspense>
  );
}
