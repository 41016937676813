import { HashRouter } from "react-router-dom";
import MainLayout from "././layouts/MainLayout";
function App(){
  return(
  <div className="App">
    <HashRouter>
      <MainLayout/>
    </HashRouter>

  </div>
);
}
export default App;